<template>
  <div class="col-md-12" v-if="!listNotAllow.includes(localName)">
    <card>
      <h4 class="card-title">
        <div class="row">
          <div class="col-md-3">
            <select :value="exchange" @change="changeExchange" :disabled="!canChangeExchange" class="text-uppercase form-control" style="wiht:70px;">
              <option value="binance"> binance </option>
              <option value="huobi" v-if="huobiHasOrders"> huobi </option>
              <option value="kucoin"> kucoin </option>
              <option value="okex"> okex </option>
            </select>
          </div>
          <div class="col-md-7">
            <base-button size="sm" simple type="success" class="ml-2"
              v-if="allowedBrokersVerify.length"
              :disabled="synchronize"
              @click="synchronizeNow()"
              >
                {{ $t('dashboard.synchronize') }}
              </base-button>
          </div>
        </div>
      </h4>

      <div class="row">
         <!-- KUCOIN -->
        <div class="col-md-3" v-if="exchange === 'kucoin'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(kucoinUsdt) }}
            </strong> USDT
          </div>
        </div>
         <!-- OKEX -->
        <div class="col-md-3" v-if="exchange === 'okex'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(okexUsdt) }}
            </strong> USDT
          </div>
        </div>
         <!-- COINEX -->
        <div class="col-md-3" v-if="exchange === 'coinex'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(coinexUsdt) }}
            </strong> USDT
          </div>
        </div>

        <!-- FTX -->
        <div class="col-md-3" v-if="exchange === 'ftx'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(ftxUsd) }}
            </strong> USD
          </div>
        </div>

        <!-- HUOBI -->
        <div class="col-md-3" v-if="exchange === 'huobi'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(huobiUsdt) }}
            </strong> USDT
          </div>
        </div>

        <!-- BINANCE -->
        <div class="col-md-3" v-if="binanceCurrency.brl >= 1 && exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.brl) }}
            </strong> BRL
          </div>
        </div>

        <div class="col-md-3" v-if="exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.usdt) }}
            </strong> USDT
          </div>
        </div>

        <!-- <div class="col-md-3" v-if="exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.busd) }}
            </strong> BUSD
          </div>
        </div> -->

        <div class="col-md-3" v-if="exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.usdc) }}
            </strong> USDC
          </div>
        </div>

        <div class="col-md-3" v-if="binanceCurrency.dai >= 1 && exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.dai) }}
            </strong> DAI
          </div>
        </div>

        <div class="col-md-3" v-if="binanceCurrency.tusd >= 1 && exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.tusd) }}
            </strong> TUSD
          </div>
        </div>
        <div class="col-md-2" v-if="binanceCurrency.pax > 0 && exchange === 'binance'">
          <div class="card-title">
            <strong class="big">
              <i class="point tim-icons" :class="addClassShow()" @click="changeCurrency()"></i> {{ renderValue(binanceCurrency.pax) }}
            </strong> PAX
          </div>
        </div>

        <div  class="col-md-12" v-if="notifications.length > 0">
           <base-alert type="warning" :key="notification.id" v-for="notification of notifications">
              <span v-html="notification.text"> </span>
            </base-alert>
        </div>
      </div>
    </card>
<!--
    <card v-if="leveling">
      <div class="card-body">
          <p>
            <a href="https://www.neio.tech/boas-praticas" target="_blank" class="text-color" style="text-decoration: underline">
              {{ $t("neioTech") }}
            </a>
          </p>
          <p>
            <a href="https://www.youtube.com/watch?v=t2Cwmsj0n8A&list=PLUzLWzMrmq78mw0zhqmMU-DioRrK05qey" target="_blank"  class="text-color" style="text-decoration: underline">
              {{ $t("youtube") }}
            </a>
          </p>
      </div>
    </card> -->

  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { BaseAlert } from '@/components'
import { notifications, userInfo, verifyAccount } from '@/services'

export default {
  name: 'balance',
  props: ["ws"],
  components: {
    BaseAlert
  },
  data() {
    return {
      notifications: [],
      leveling: false,
      localName: '',
      listNotAllow: [
        'CompanyOverviewUser',
        'CompanyErrorsUser',
        'CompanyOperationsClosedUser',
        'CompanyOperationsOpenUser',
        'CompanyEditOrdersUser',
        'CompanyNewOrdersUser',
        'CompanyOrdersUser',
        'Company',
        'CompanyUsers',
        'CompanyNewUser',
        'CompanyEditUser',
        'CompanyOrdersUser',
        'CompanyFinancial'
      ]
    };
  },
  methods: {
    ...mapActions({
      setStatus: "exchange/setStatus",
      setExchange: 'user/setExchange',
      setCanChangeExchange: 'user/setCanChangeExchange',
      setChangeBroker: 'user/setChangeBroker',
      setShow: 'user/setShowBalance',
      setSynchronize: 'user/setSynchronize'
    }),

    synchronizeNow() {
      this.setSynchronize(true)
      this.ws.emit('verifyUser', {})
      setTimeout(() => this.setSynchronize(false), 300000);

      this.allowedBrokersVerify.forEach(broker => {
        const key = broker.name.toLowerCase()

        verifyAccount(key)
          .then(({ errors = [] }) => {
            let value = { error: '' }

            if (errors.length > 0) {
              value.error = errors[0].message
              this.setStatus({ key, value })
              this.$notify({ type: 'danger', message: `(${key})  Ops: ${value.error}`, verticalAlign: 'top', horizontalAlign: 'center', timeout: 30000 })
            } else {
              this.setStatus({ key, value })
              this.$notify({ type: 'success', message: this.$t("apiOk", { exchange: key }) , verticalAlign: 'top', horizontalAlign: 'center', timeout: 25000 })
            }
          }).catch(({ response }) => {
            console.info(response.data.errors[0].message)
          })
      })



    },

    renderValue(value) {
      let newValue =  (value || 0).toFixed(2)
      if (this.show) return newValue

      let text = '';
      for (let index = 0; index < newValue.length; index++) text += '*';
      return text;
    },

    changeExchange(event) {
      this.setExchange(event.target.value)
      this.setChangeBroker(false)
      setTimeout(() => this.setChangeBroker(true), 1600);
    },

    addClassShow() {
      return {
        "icon-minimal-down" : this.show,
        "icon-minimal-up" : !this.show
      }
    },

    changeCurrency() {
      this.setShow(!this.show)
    },

    loadNotifications() {
      notifications()
      .then(({ data }) => {
        this.notifications = data.notifications.map(n => ({ id: n.id, text: n.text })) || [];
      });
    }
  },
  async created() {
    this.loadNotifications()

    if (['OrdersNew', 'OrdersEdit'].includes(this.$route.name))
      this.setCanChangeExchange(false)
    else
      this.setCanChangeExchange(true)

    this.localName = this.$route.name

  },
  computed:{
    ...mapGetters({
      brokers: "exchange/brokers",
      operations: 'user/exchangeOperations',
      huobiHasOrders: 'user/huobiHasOrders',
      exchange: 'user/exchange',
      canChangeExchange: 'user/canChangeExchange',
      binanceCurrency: 'user/binanceCurrency',
      kucoinUsdt: 'user/kucoinUsdt',
      coinexUsdt: 'user/coinexUsdt',
      okexUsdt: 'user/okexUsdt',
      ftxUsd: 'user/ftxUsd',
      huobiHusd: 'user/huobiHusd',
      huobiUsdt: 'user/huobiUsdt',
      show: 'user/showBalance',
      synchronize: 'user/synchronize',
      blocked: 'user/blocked',
      showAdmin: 'user/showAdmin',
      onlyView: "user/onlyView"
    }),



    allowedBrokersVerify() {
      return this.brokers
        .filter(broker => broker.blocked === false)
        .filter(broker => broker.active === true)
    },
  }
};
</script>
<style scoped>
  strong.big {
    font-size: 1.1em;
    font-weight: 700;
  }

  .point {
    cursor: pointer;
  }

  .text-color {
    color: #FFFFFF;
  }

  .white-content .text-color {
    color: #5603ad;
  }
</style>
